import React from "react"
import Layout from "@components/layout"
import Heading from "@components/elements/heading"
import Flex from "@components/elements/flex"
import Paragraph from "@components/elements/paragraph"
import Anchor from "@components/elements/anchor"

const MediaResourcesPage = () => {
  return (
    <Layout
      title={`Media Resources`}
      description={`Klipfolio is trusted and relied on by over 11,000 of the world’s best and brightest organizations. As a leader in the cloud-based business intelligence dashboarding and reporting market, Klipfolio is a cost-effective, flexible analytics solution that integrates with hundreds of data services to create powerful business insights.`}
      fullWidth
      marginless
      hideOverflow
    >
      <Flex gap="2rem" container margin="0 auto 6rem">
        <Heading fontSize="3rem" as="h1" margin="8rem 0 2rem">
          Media Resources
        </Heading>
        <Heading as="h3">About Klipfolio Inc.</Heading>
        <Paragraph>
          {`Klipfolio is trusted and relied on by over 11,000 of the world’s best
          and brightest organizations. As a leader in the cloud-based business
          intelligence dashboarding and reporting market, Klipfolio is a
          cost-effective, flexible analytics solution that integrates with
          hundreds of data services to create powerful business insights. With
          customers in healthcare, financial services, consumer goods,
          non-profit, and marketing, sales, support, Klipfolio is changing the
          way data is used one Klip at a time.`}
        </Paragraph>
        <Paragraph>
          To learn more, visit the <Anchor link="/">Klipfolio website</Anchor>{" "}
          or follow us on{" "}
          <Anchor link="https://twitter.com/klipfolio">Twitter</Anchor>,{" "}
          <Anchor link="https://www.linkedin.com/company/klipfolio">
            LinkedIn
          </Anchor>
          , <Anchor link="https://www.facebook.com/Klipfolio">Facebook</Anchor>.
        </Paragraph>
        <Heading as="h3" margin="2rem 0 0">
          For more information
        </Heading>
        <Paragraph>
          For media enquiries, please contact <b>Cathrin Schneider</b> at{" "}
          <Anchor link="mailto:cschneider@klipfolio.com">
            cschneider@klipfolio.com
          </Anchor>
        </Paragraph>
      </Flex>
    </Layout>
  )
}

export default MediaResourcesPage
